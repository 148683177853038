<template>
  <div class="tva">
    <Banner>
      <div class="banner-content">
        <MeepIconSimulateurs class="banner-content__icon" />
        <span class="banner-content__text">{{ $t("menu.simulateurs") }}</span>
      </div>
    </Banner>

    <div class="tva-content page-layout">
      <md-card class="meep-form md-card-small">
        <PageHeader
          :title="$t('simulateurs.tva.title')"
          :has-back="true"
          @back="goBack()"
        />
        <md-card-content>
          <div class="meep-form-column meep-form-single-column">
            <div class="meep-input">
              <md-field>
                <label>{{ $t("simulateurs.tva.montant") }}</label>
                <md-input
                  v-model.number="$v.form.montant.$model"
                  name="montant"
                />
                <span class="md-suffix">{{ $t("suffix.euro") }}</span>

                <template v-if="$v.form.montant.$dirty">
                  <span v-show="!$v.form.montant.numeric" class="meep-error">
                    {{ $t("meep-errors.number") }}
                  </span>
                  <span v-show="!$v.form.montant.required" class="meep-error">
                    {{ $t("meep-errors.required") }}
                  </span>
                </template>
              </md-field>
            </div>

            <!-- Vehicle Type -->
            <div class="meep-input">
              <md-field>
                <label for="scope">
                  {{ $t("simulateurs.tva.taux") }}
                </label>

                <md-select id="type" v-model="$v.form.taux.$model" name="type">
                  <md-option
                    v-for="(taux, index) in tauxs"
                    :key="index"
                    :value="taux.value"
                  >
                    {{ taux.text }}
                  </md-option>
                </md-select>
              </md-field>
            </div>

            <Chooser
              v-model="form.sens"
              :value="form.sens"
              :items="[
                { label: 'HT vers TTC', value: 'ht_ttc' },
                { label: 'TTC vers HT', value: 'ttc_ht' },
              ]"
            />
            <div class="md-layout md-alignment-center-right middle-button">
              <md-button class="md-raised md-primary" @click="calcu(form)">
                {{ $t("simulateurs.cal-button") }}
              </md-button>
            </div>

            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.tva.montant-black") }}</label>
                <p class="result">
                  {{ $$filters.formatNumber(results.ht) + $t("suffix.euro") }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
            <div class="meep-input meep-input-result">
              <md-field>
                <label>{{ $t("simulateurs.tva.taux-black") }}</label>
                <p class="result">
                  {{ $$filters.formatNumber(results.tva) + $t("suffix.euro") }}
                </p>
                <md-input class="md-input-result" disabled />
              </md-field>
            </div>
          </div>
        </md-card-content>
      </md-card>
    </div>
  </div>
</template>

<script>
import Banner from "@/components/Banner";
import MeepIconSimulateurs from "@/components/icons/MeepIconSimulateurs.vue";
import HasBackMixin from "@/mixins/HasBackMixin";
import { required, numeric, decimal } from "vuelidate/lib/validators";
import PageHeader from "@/components/PageHeader";
import calculatorsModel from "@/model/calculators";
import Chooser from "@/components/Chooser.vue";
import { tauxs } from "../../constants";

export default {
  name: "Tva",
  mixins: [HasBackMixin],

  components: {
    Banner,
    MeepIconSimulateurs,
    PageHeader,
    Chooser,
  },

  validations: {
    form: {
      montant: { required, numeric },
      taux: { decimal },
    },
  },

  data() {
    return {
      form: {
        montant: null,
        taux: null,
        sens: "ttc_ht",
      },
      tauxs,
      results: {
        ht: 0,
        tva: 0,
      },
      tvaData: [],
    };
  },

  async mounted() {
    this.tvaData = await calculatorsModel.getAllKilo();
  },

  methods: {
    calcu({ montant, taux, sens }) {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        if (!taux) {
          taux = 120;
        }
        const minus =
          sens === "ttc_ht" ? montant * (100 / taux) : montant / (100 / taux);

        const tva = Math.abs(montant - minus);

        this.results = {
          ht: minus,
          tva: tva,
        };
      }
    },
  },
};
</script>
