<template>
  <section
    class="chooser"
    :class="{
      'chooser-no-margin': noMargin,
    }"
  >
    <label v-if="label" class="chooser-title">{{ label }}</label>

    <v-radio-group row>
      <v-radio
        v-for="(item, index) in items"
        :key="index"
        :label="item.label"
        :value="item.value"
        @click="changeValue(item.value)"
        :class="{
          'v-item--active': value === item.value,
          'v-radio-two': twoItems,
        }"
      ></v-radio>
    </v-radio-group>
  </section>
</template>

<script>
export default {
  name: "chooser",
  props: {
    label: {
      type: String,
      default: "",
    },

    items: {
      type: Array,
      required: true,
    },

    value: {
      default: true,
    },

    noMargin: {
      type: Boolean,
      default: false,
    },

    twoItems: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    changeValue(value) {
      this.$emit("input", value);
    },
  },
  computed: {},
};
</script>

<style lang="scss">
@import "../styles/_variable.scss";
#app .chooser {
  margin-top: 25px;

  .v-input--selection-controls__input {
    display: none;
  }

  .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 0px;
  }

  .v-input {
    margin-top: 0;
    padding-top: 2px;
    &__control {
      .v-input__slot {
        padding: 0;
        margin: 0;
      }

      .v-messages {
        display: none;
      }
    }
  }

  .v-input--radio-group__input {
    justify-content: space-between;
    gap: 14px;
  }

  .v-radio {
    position: relative;
    height: 32px;
    flex-grow: 1;
    margin: 0;
    border-radius: 11px;
    display: flex;
    background: #f2f5f7;
    align-items: center;
    @include for-lg {
      height: 50px;
    }
    &-two {
      flex-basis: 48%;
    }

    .v-label {
      justify-content: center;
      color: var(--text-inverted, #000);
      font-size: toRem(9);
      font-family: var(--font-extrabold, var(--font-medium));
      @include for-lg{
        font-size: toRem(15);
      }
    }

    &.v-item--active {
      background: var(--bg-primary);
      .v-label {
        color: var(--text-primary);
      }
    }

    &::before {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
      will-change: background-color, opacity;
      border-radius: 11px;
      content: " ";
    }

    &:hover::before {
      background-color: currentColor;
      opacity: 0.12;
    }
  }

  &-title {
    font-size: 17px;
    font-family: var(--font);
  }

  &-no-margin {
    margin: 0;
  }
}
</style>
